@import 'vars';

.card__cell {
  .card__add-card {
    .glyphicon-plus {
      visibility: hidden;
    }
  }

  &:hover .card__add-card {
    .glyphicon-plus {
      visibility: visible;
    }
  }

  .card__body-wrapper {
    margin-bottom: 2px;

    &.lastOne {
      margin-bottom: 2px;
    }

    .card__drop-zone {
      text-align: center;
      margin-bottom: 4px;
      border: 2px dashed $gray-8;
      padding-top: 4px;
      font-size: 10px;
      width: 85%;
      margin: auto;

      svg {
        color: $gray-8;
      }
    }

    .card__title {
      @include scenecard-title-font;
      word-break: break-word;
    }

    .medium-timeline {
      .card__title {
        @include scenecard-title-font;
        @include medium-scenecard-title-font-size;
      }
    }
  }

  .card__add-card {
    font-size: 12px;
    text-align: center;
    color: transparent;
    cursor: pointer;
  }

  &:hover {
    .card__add-card {
      color: $blue-5;
    }
  }

  &.vertical {
    @include scenecard-title-font;
    display: flex;

    & > div {
      display: flex;
    }

    & > div:first-child {
      padding-left: 25px;
    }

    .card__add-card {
      height: 70px;
      line-height: 70px;
    }

    &:not(.multiple) {
      .card__add-card {
        align-items: center;
        align-self: center;
      }
    }
  }

  &.vertical.medium-timeline {
    @include medium-scenecard-title-font-size;
    & > div:first-child {
      padding-left: 29px;
    }
  }
}

.card__cell__overview-cell {
  @extend .card__cell;

  cursor: pointer;

  &.vertical {
    .card__behind {
      left: 48px;
      right: unset !important;
    }

    .card__cell__overview__drag-anchor {
      &.medium-timeline {
        margin-left: 12.5px;
      }
    }
  }

  &.medium-timeline:not(.vertical) {
    max-width: 72px;

    .card__behind {
      left: 4px;
    }

    .card__add-card {
      margin-left: 20px;
    }
  }

  .card__cell__overview__drag-anchor {
    width: 150px;
    margin-left: 12.5px;
    margin-right: 12.5px;

    &.medium-timeline {
      width: 80px;
      margin-left: 10px;
    }
  }

  span:first-child {
    text-align: left;
  }

  .card__body {
    cursor: move;

    .card__title {
      @include scenecard-title-font;
      font-style: italic;
    }
  }

  .card__behind {
    position: absolute;
    width: 150px;
    min-height: 70px;
    right: 6px;
    top: 8px;
    border: 3px solid black;
    border-radius: 4px;
    z-index: 1;
    background-color: $body-background-color;
    cursor: pointer;
  }

  .card__add-card {
    margin-top: 8px;
  }

  &.vertical {
    @include scenecard-title-font;
    .card__add-card {
      display: block;
      z-index: 10;
      margin-top: 0px;
    }

    &:hover .card__add-card {
      .glyphicon {
        background-color: $body-background-color;
        padding: 2px 2px 2.5px 3px;
        border: 1px solid $blue-7;
        border-radius: 3px;
        color: $blue-5;
      }
    }

    .card__behind {
      right: 18px;
      bottom: 16px;
    }

    .card__drop-zone {
      margin: auto;
    }
  }

  &.medium-timeline {
    @include medium-scenecard-title-font-size;
    .card__behind {
      right: -2px;
      width: 80px;
      min-height: 70px;
      max-height: fit-content;
      top: 8px;
    }
  }

  &.vertical.medium-timeline {
    .card__behind {
      left: 38px;
      top: 8px;
    }
  }
}

.__floater__body {
  max-height: 80vh;
  overflow-y: auto;
}

.card__hidden-cards {
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  max-height: calc(0.8 * 100vh);
  overflow-y: auto;

  .card__body-wrapper {
    margin-bottom: 6px;

    &.lastOne {
      margin-bottom: 0;
    }
  }
}

.card__add-card.dropping {
  background-color: $gray-8;
}

.card__body {
  width: 150px;
  min-height: 80px;
  display: flex;
  border: 3px solid black;
  border-radius: 4px;
  margin: auto;
  position: relative;
  background-color: $body-background-color;
  cursor: move;
  transition: box-shadow 0.6s $cubic, transform 0.6s $cubic;
  z-index: 2;

  &.creating {
    padding: 1px 8px 0 8px;

    .form-group {
      margin-bottom: 0;

      .control-label {
        margin-bottom: 0;
      }

      .form-control {
        padding: 5px 6px;
      }
    }
  }

  &.medium-timeline {
    width: 80px;

    .card__title {
      @include scenecard-title-font;
      @include medium-scenecard-title-font-size;
      padding: 6px;
    }
  }

  &.shadow {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
  }
}

.card__title {
  padding: 10px;
  flex-grow: 1;
  overflow: auto;
  word-break: break-word;
}

.card__svg-line {
  stroke-width: $svg-line-width;
}

.vertical-blank-card__wrapper {
  margin: auto;
}

.blank-card__body {
  @extend .card__body;
  border: 3px dashed black;
  visibility: hidden;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  div {
    padding: 10px 5px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.template {
    @include application-text;
    text-align: center;
    font-size: 14px;
  }

  &.medium-timeline {
    flex-direction: column-reverse;
    margin-bottom: 0;

    div.template {
      font-size: 12px;
    }
  }

  &.drop-disabled {
    background: none;

    .non-template {
      .glyphicon {
        background-color: $body-background-color;
        font-size: 50px;
      }
    }
  }

  &.create-disabled {
    cursor: not-allowed;
  }
}

.blank-card__body.disabled {
  cursor: not-allowed;
}

.vertical-blank-card__body {
  @extend .card__body;
  border: 3px dashed black;
  visibility: hidden;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  div {
    padding: 10px 5px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.template {
    @include application-text;
    text-align: center;
    font-size: 14px;
  }

  &.medium-timeline {
    font-size: 12px;
    flex-direction: column-reverse;

    div {
      padding: 6px 4px;
    }
  }
}

.vertical-blank-card__body.disabled {
  cursor: not-allowed;
}

.blank-card__body,
.vertical-blank-card__body {
  &.hover {
    visibility: visible;
  }
}

.card__cell:hover {
  .blank-card__body,
  .vertical-blank-card__body {
    visibility: visible;
  }
}

.card__body:hover {
  transform: scale(1.01);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.card__popover-wrapper {
  padding: 4px 16px;
}

.card__popover-description {
  min-width: 250px;
  @include rce-text-font;

  .slate-editor__editor {
    text-align: justify;
    @include rce-text-font;

    .slate-editor__word-counter {
      bottom: 10px;
      right: 10px;
      background-color: #fff;
      border: 1px solid $new-item-border-color;
      color: #102a42;
    }
  }
}

.card__popover-labels {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 12px;

  .label {
    margin: 2px;
  }
}

.sticky-table.darkmode {
  .card__cell {
    background-color: $darkmode-card-background;

    .card__body,
    .blank-card__body {
      background-color: $darkmode-card-background;
      color: $darkmode-text;
    }
  }

  .card__cell__overview-cell {
    .card__behind {
      background-color: $darkmode-card-background;
    }
  }
}

.darkmode {
  .card__hidden-cards {
    background-color: $darkmode-v2-background;
  }

  .card__body,
  .card__behind {
    background-color: $darkmode-v2-card-background !important;
    color: $darkmode-v2-text;
  }

  .card__cell {
    .card__body-wrapper {
      .card__title {
        @include scenecard-title-font;
        color: $darkmode-v2-darker-text;

        &:hover {
          color: $darkmode-v2-darker-text;
        }
      }

      .medium-timeline {
        .card__title {
          @include medium-scenecard-title-font-size;
        }
      }
    }
  }

  .card__popover {
    .popover-title {
      color: $darkmode-v2-darker-text;
    }
  }

  .card__popover-description {
    .slate-editor__editor {
      @include rce-darkmode-font-color;
      color: inherit;

      h1 {
        @include header-font;
        @include rce-title-darkmode-font-color;
      }

      h2 {
        @include sub-header-font;
        @include rce-subtitle-darkmode-font-color;
      }

      .slate-editor__word-counter {
        border: 1px solid $darkmode-v2-border;
        background-color: $darkmode-v2-background;
        color: $darkmode-v2-darker-text;
      }
    }
  }
}
