@import '../colors';
@import '../vars';

.dashboard__account {
  height: 100%;
  overflow: auto;
  padding: 32px;

  hr {
    border-top: 1px solid $neutral-gray-7;
  }

  div.text-right p.secondary-text {
    margin-top: 8px;
  }

  h1 {
    font-size: 20pt;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .dashboard__acount__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.dashboard__account__header {
  h1 {
    margin-top: 0;
    font-family: 'Yellowtail' !important;
    font-weight: 400 !important;
    font-size: 36pt !important;
    img {
      vertical-align: -80%;
      display: inline;
    }
  }
}

.dashboard__account__nav-tabs {
  display: flex;
  justify-content: center;
}

.dashboard__user-info {
  margin-bottom: 20px;

  h2 {
    font-size: 16pt;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .dashboard__user-info__wrapper {
    display: flex;

    dd,
    dt {
      padding: 8px 0;

      &.lead {
        margin-bottom: 0;
        margin-top: -6px;
      }
    }
  }
  .text-danger.darkmode {
    color: $red-6;
  }

  a.darkmode {
    color: $blue-6;
  }
}

.dashboard__about {
  padding: 32px;

  h1 {
    font-size: 20pt;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .dashboard__about__wrapper {
    display: flex;

    dd,
    dt {
      padding: 8px 0;
      font-size: large;
    }
  }
}
