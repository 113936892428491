@import 'colors';
@import 'vars';
@import '../lib/plottr_components/src/css/components.scss';

$text-color: $gray-0;

@import 'scrollbars';
@import 'error_boundary';
@import 'home';
@import 'login';

@import 'dashboard/dashboard';

/* Tailwind sets these to display: block, but the website assumes inline. */
svg {
  display: inline;
}

.alert {
  margin-bottom: 0px;
}

@import 'darkmode';

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Give everything in 'yield' margin to separate it.
#__next {
  margin: 0px 0px 0px 0px;
  height: 100vh;

  display: grid;
  grid-template-areas: 'navigation' 'main';
  grid-template-rows: auto 1fr;

  main.project-main {
    grid-area: main;
    overflow: hidden;
  }

  nav.project-nav {
    grid-area: navigation;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    z-index: 1000;
    .container-fluid {
      padding: 0;

      .navbar-collapse {
        padding-right: 0;

        &.in {
          margin-left: 0;
        }
      }

      .navbar-right {
        margin-right: 0;
      }

      .navbar-header {
        margin-right: 0;
        margin-left: 0;
        .navbar-toggle {
          border-color: $gray-6;
          margin-left: 16px;
          float: none;
        }
      }
    }

    .navbar-save-controls {
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
      li {
        margin-left: 10px;
      }
    }

    &.navbar-inverse {
      .navbar-toggle {
        border-color: hsl(0, 0%, 20%);
      }
    }
  }

  .project-nav__options {
    padding-right: 0;
    button {
      font-size: 15px;
      svg {
        margin-right: 4px;
      }
    }
  }

  .project-nav__share-button {
    svg {
      margin: 0px !important;
    }
  }

  .project-nav__dashboard-button {
    margin-left: 15px;
  }

  .project-nav__beamer-button {
    margin-right: 18px;
    margin-left: 15px;
  }
}

body {
  @include body-text-font;
  background-color: $gray-9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include header-font;
}

h3.popover-title {
  font-size: 12pt !important;
}

// fix an annoying focus-ring that Chromium adds
.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
  outline: 0px auto -webkit-focus-ring-color;
}

.secondary-text {
  @include secondary-text;
}

.secondary-text.darkmode {
  color: $darkmode-darker-text;
}

.disabled-text {
  @include disabled-text;
}

.accented-text {
  @include accented-text;
}

.sub-header {
  @extend .secondary-text;
  font-size: 16pt;
  margin-bottom: 20px;
}

.glyphicon {
  vertical-align: baseline;
}

.glyphicon-ok {
  vertical-align: baseline;
}

.svg-save-template {
  vertical-align: middle;
}
.navbar-brand {
  @include header-font;
  font-size: 24px;
}
.nav.navbar-nav {
  @include application-text;
}
.navbar-default .navbar-text {
  color: $green-3;
}
.btn {
  @include application-text;
}
.modal-header {
  @include header-font;
}

.control-label {
  @include application-text;
}
input[type='file'] {
  @include application-text;
}

.blue-background {
  background-color: $blue;
}

@keyframes rotateit {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.fa-spinner {
  animation: rotateit 1.1s linear infinite;
}

.fun-spinner {
  margin-top: 100px;
  width: 100vw;
  text-align: center;
  font-size: 25px;
  @include header-font;
  .fa-spinner {
    color: $orange-5;
    font-size: 2.5em;
    margin-bottom: 8px;
  }
}

.popover {
  .popover-content {
    max-height: 100vh;
    padding-bottom: 0;
  }
}
.plottr-popover {
  .popover-content {
    // 35 is the height of the popover title
    max-height: calc(80vh - 35px);
    overflow: auto;
  }
}
#list-popover.popover {
  .popover-content {
    max-height: 93vh;
    overflow: auto;
  }
}
#filter.popover {
  .popover-content {
    max-height: 83vh;
    overflow: auto;
  }
}

#beamer-bell {
  .beamer_icon {
    top: 5px;
  }
  svg {
    vertical-align: text-bottom;
  }
}

.tour-helper {
  padding-right: 60px !important;
  max-width: 700px !important;
  font-size: 18pt;

  [data-tour-elem='controls'] {
    margin-top: 40px !important;
  }

  button:not([data-tour-elem='dot']) {
    height: 15px !important;
    width: 15px !important;
  }

  button[data-tour-elem='left-arrow'],
  button[data-tour-elem='right-arrow'] {
    height: 20px !important;
    width: 20px !important;
  }

  .reactour__dot {
    height: 12px !important;
    width: 12px !important;
  }
}

#temporary-inner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#temporary-inner img {
  margin-top: 16px;
}

.loading-splash {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-splash__progress {
  width: 40vw;
}

.loading-splash__progress__bar {
  position: relative;
  height: 3px;
  background: $orange;
  transition: width 0.4s;
}

.darkmode {
  #temporary-inner.darkmode {
    background-color: $darkmode-v2-background;

    h3 {
      color: $darkmode-v2-darker-text !important;
    }
  }
}
