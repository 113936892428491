@import '../colors';
@import '../vars';

.dashboard__files {
  height: 100%;
  width: 100%;
  padding: 32px;
  padding-bottom: 0;
  display: grid;
  grid-template-areas:
    'options'
    'body';
  grid-template-rows: auto 1fr;
  overflow: hidden;
}

.ReactModal__Content
  > #dashboard__react__root
  > .dashboard__main
  > .dashboard__body
  > .dashboard__files {
  height: 90%;
}

.dashboard__new-files {
  grid-area: options;
  width: 95%;
  height: 25vh;
  max-height: 30vh;

  .import-file {
    padding: 0;
  }

  .import-file.dropdown {
    ul {
      width: 100%;
    }
  }

  .import-file .dropdown-toggle {
    width: 100%;
    border: none;
    box-shadow: none;
    background: transparent !important;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: calc(14 * 0.1vw);
    padding: calc(15 * 0.1vw) calc(11 * 0.1vw);

    svg {
      font-size: calc(50 * 0.1vw);
      color: $gray-5;
      &:hover,
      &.active {
        svg {
          color: $gray-3;
        }
      }
    }
  }
}

.dashboard__new-files__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.dashboard__new-files__item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.1s ease-in;
  font-size: calc(14 * 0.1vw);
  color: $gray-1;
  background: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  padding: calc(15 * 0.1vw) calc(11 * 0.1vw);
  height: 20vh;

  &.disabled {
    cursor: not-allowed;
  }

  &.icon {
    svg {
      font-size: calc(50 * 0.1vw);
      color: $gray-5;
    }
    &:hover,
    &.active {
      svg {
        color: $gray-3;
        // transform: scale(1.2);
      }
    }
  }

  &:hover,
  &.active {
    // color: $blue-0;
    background-color: $blue-9;
    box-shadow: $blue-highlight-box-shadow;
  }
}

@media (max-width: 600px) {
  .dashboard__new-files__item {
    height: 10vh;
  }
}
@media (max-width: 1050px) {
  .dashboard__new-files__item {
    height: 15vh;
  }
}

.template-picker__dialog-wrapper {
  grid-area: body;
  position: relative;
  overflow: hidden;
}

.dashboard__recent-files {
  grid-area: body;
  position: relative;
  overflow: hidden;

  h1 {
    font-size: 20pt;
    margin: 0;
  }

  .dashboard__search {
    // width: 300px;
    // float: right;
  }

  svg:nth-child(2),
  span:nth-child(2) {
    margin: 15px;
  }

  .container-fluid {
    width: 95%;
  }
}

.dashboard__recent-files__table {
  margin-top: 16px;
  width: 100%;
  height: calc(100% - 60px); // 36 + 16 + 8 (grid row + margin + extra)
  .sticky-table {
    // border-radius: 4px;
  }

  .sticky-table-table {
    width: 100%;
    white-space: inherit;
    word-break: break-word;
    // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    .sticky-table-row:first-child {
      .sticky-table-cell {
        height: 40px;
        padding: 10px 20px;
        background-color: $orange-6;
        color: white;
        border: none;
        font-size: large;
        // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        &:first-child,
        &:nth-child(2) {
          border-right: 1px solid white;
        }
      }
    }

    .sticky-table-row .sticky-table-cell:nth-child(2),
    .sticky-table-row .sticky-table-cell:nth-child(3) {
      width: 17%;
    }

    .dashboard__recent-files__file-cell {
      display: flex;
      align-items: center;
      width: 100%;

      div:first-child {
        width: 100%;
      }

      .title {
        font-size: large;
        .glyphicon {
          margin-right: 8px;
          color: $red-5;
        }
      }
      .secondary-text {
        font-size: small;
        white-space: pre-wrap;
      }
      .dashboard__recent-files__file-actions {
        margin-left: auto;
        visibility: hidden;
        width: 0;

        button .glyphicon,
        button svg {
          vertical-align: baseline;
        }

        .btn-group {
          width: 100%;
          display: flex;
          word-break: break-word;
          button svg {
            font-size: 18px;
          }
          > button {
            height: 33.5px;
            padding: 5px 10px;
          }
        }
      }
    }

    .sticky-table-row:not(:first-child) {
      border-left: 1px solid #0000001a;
      .sticky-table-cell {
        padding: 12px;
        border: 1px solid #0000001a;
        cursor: pointer;
        user-select: none;

        &.disabled {
          cursor: not-allowed;
        }
        &:first-child {
          border-width: 0 0 1px 1px;
        }
        &:nth-child(2) {
          border-width: 0 0 1px 1px;
        }
        &:last-child {
          border-width: 0 1px 1px 1px;
        }

        .lastOpen {
          display: flex;
          flex-direction: row;

          span:nth-child(2) {
            margin: 5px;
          }
        }

        .permissions {
          display: flex;
          align-items: baseline;
          svg {
            margin-right: 8px;
          }
        }
      }

      &:hover,
      &.selected {
        color: $blue-0;
        background-color: $blue-9;
        // box-shadow: $blue-highlight-box-shadow;
        .sticky-table-cell:first-child {
          // background-color: $green-8;
          color: inherit;
          background-color: inherit;
          // this doesn't work because each cell has the border and it doesn't look good
          // only sorta looks ok with the selector :first-child on the cell
          box-shadow: inherit;
        }
        .dashboard__recent-files__file-actions {
          width: auto;
          visibility: visible;
        }
      }
    }
    .sticky-table-row:nth-of-type(odd):not(:first-child) {
      .sticky-table-cell {
        background-color: $gray-9;
      }
    }
  }
}
