@import 'vars';

.select-list__item-select-list {
  list-style-type: none;
  padding-left: 10px;
  margin-bottom: 0;
  max-width: 60vw;
  li {
    user-select: none;
    cursor: pointer;
    white-space: break-spaces;
    word-break: break-all;
    padding: 5px;
    span.colored {
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 3px;
    }
  }
  li:hover {
    &:not(.select-list__line) {
      background-color: $body-background-color;
    }
  }
  .select-list__line {
    cursor: default;
    hr {
      border-top: 1px solid darken(hsl(210deg, 36%, 96%), 3%);
      padding: 0;
      margin: 0 -14px 0 -24px;
    }
  }
  &.series-select {
    li {
      margin-left: 5px;
    }
    .series {
      margin-left: -15px;
    }
  }
}

.select-list__details-label {
  @include application-text;
  min-width: 150px;
}

.select-list__wrapper {
  label {
    button {
      margin-left: 2px;
      visibility: hidden;
    }
  }
  label:hover {
    button {
      visibility: visible;
    }
  }
  ul {
    list-style-type: none;
    padding-left: 10px;
    li {
      button {
        margin-right: 2px;
        visibility: hidden;
      }
    }
    li:hover {
      button {
        visibility: visible;
      }
    }
  }

  &.horizontal {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .select-list__details-label {
      min-width: unset;
      font-weight: normal;
    }
    .chip-cloud {
      margin: 0;
    }
  }
}

.chip-cloud {
  display: flex;
  flex-wrap: wrap;
}

.chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  font-size: 14px;
  line-height: 16px;
  border-radius: 2rem;
  justify-content: space-between;
  min-width: 75px;
  max-width: fit-content;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 8px;
  margin: 2px;
  white-space: break-spaces;
  cursor: default;

  .image-circle-xs {
    margin: 5px 4px 0px -8px;
  }

  .glyphicon-remove {
    visibility: hidden;
    margin-left: 4px;
    cursor: pointer;
    align-self: center;
  }

  &:hover {
    background-color: $gray-9;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    .glyphicon-remove {
      visibility: visible;
    }
  }

  .fa-spinner {
    margin-right: 5px;
  }
}

.tag-chip {
  display: inline-flex;
  user-select: none;
  align-items: center;
  justify-content: space-between;
  margin: 2px;
  cursor: default;

  .glyphicon-remove {
    visibility: hidden;
    margin-left: 2px;
    cursor: pointer;
  }

  &:hover {
    background-color: $gray-9;
    .label {
      color: $red-5 !important;
      background-color: $gray-9 !important;
      border-color: transparent !important;
    }
    // box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    .glyphicon-remove {
      visibility: visible;
    }
  }

  .label.label-info {
    white-space: inherit;
    word-break: break-word;
  }
}

body.darkmode {
  .select-list__item-select-list {
    li:hover {
      background-color: $darkmode-darker-background;
    }
  }

  .select-list__line {
    cursor: default;
    background-color: transparent !important;
    hr {
      border-top: 1px solid $darkmode-v2-border;
      background-color: transparent;
    }
  }

  .chip {
    color: $darkmode-v2-text;
    .glyphicon-remove {
      color: $darkmode-v2-darker-text;
    }

    &:hover {
      background-color: $darkmode-darker-background;
    }
  }

  .tag-chip {
    .glyphicon-remove {
      color: $darkmode-darker-text;
    }
    &:hover {
      background-color: $darkmode-darker-background;
    }
  }
}
