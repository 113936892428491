@import 'vars';

.onboarding__wrapper {
  text-align: center;
  height: 100%;
  overflow: hidden;
  h1 {
    margin: 16px 0;
  }
}

.onboarding__progress {
  width: 85%;
  margin: auto;
  .onboarding__progress__titles {
    padding: 4px 16px;
    display: flex;
    justify-content: space-between;
  }
  .progress {
    height: 27px;
    margin-bottom: 0;
    background-color: white;
    border: 1px solid $gray-8;
  }
}

.onboarding__step {
  max-height: 82%;
  overflow: auto;
}

.step__header {
  padding: 16px;
  p {
    font-size: 20px;
    margin: 16px 0;
    color: $blue-6;
  }
}

.step__body {
  padding: 16px;
  .jumbotron {
    max-width: 60%;
    border-radius: 20px;
    margin: auto;
    background-color: $neutral-gray-8-half;
    ol {
      font-size: 20px;
      text-align: left;
      padding-left: 60px;
      color: $text-color;
      li {
        padding: 4px;
      }
    }
    .accented-text {
      color: $orange-4;
    }
  }
  .form-group {
    max-width: 50%;
    margin-left: 25%;
    text-align: left;
  }
  .alert {
    h4 {
      margin-bottom: 0;
    }
  }
  .list-group {
    text-align: left;
    .list-group-item {
      p.secondary-text {
        font-size: 12px;
        margin-left: 20px;
        @include secondary-text;
      }
      .checkbox {
        padding-left: 0;
      }
      &:hover {
        background-color: $gray-9;
      }
    }
  }
  h5.with-links {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    small span,
    a {
      padding-right: 2px;
    }
    small {
      font-size: 60%;
      text-transform: uppercase;
    }
  }
  .progress {
    background-color: white;
    border: 1px solid $gray-8;
  }
}

.step__footer {
  padding: 32px 16px 16px 16px;
  .large-text {
    font-size: 20px;
  }
}

.onboarding__button-bar {
  display: flex;
  width: 85%;
  margin: auto;
  justify-content: space-around;
  align-items: center;

  &.right {
    justify-content: flex-end;
    padding-right: 64px;
  }
  &.left {
    justify-content: flex-start;
  }
  p {
    font-size: 24px;
    margin: 0;
    margin-right: 16px;
  }
}

.onboarding__settings {
  text-align: left;
  padding-left: 15%;
  .dashboard__options__item {
    select {
      font-size: 20px;
    }
  }
}

.darkmode {
  .settings-wizard__wrapper {
    .onboarding__wrapper {
      .onboarding__step {
        background-color: $darkmode-v2-darker-background;

        > svg {
          color: $darkmode-v2-darker-text;
        }
      }
    }
  }

  .step__header,
  .step__body {
    > svg {
      color: $darkmode-v2-darker-text;
    }
  }

  .progress {
    background-color: $darkmode-v2-progress;
    border: none;
  }

  .onboarding__scrolling-list {
    .list-group-item {
      background-color: $darkmode-v2-card-background;
      border-color: $darkmode-v2-border;

      &:hover {
        background-color: $darkmode-v2-darker-background;
      }

      p.secondary-text {
        color: #ccc;
        word-break: break-word;
      }
      .checkbox {
        color: $darkmode-v2-darker-text;
        padding-left: 0;
      }
      span {
        word-break: break-word;
      }
    }
  }
}
